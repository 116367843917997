import React, { useEffect } from "react";
import { useAppState } from "@state";
import classNames from "classnames";
import loadable from "@loadable/component";
import { LazyMotion } from "framer-motion";

const LakotaHeader = loadable(() => import("@organisms/LakotaHeader"));
const Modal = loadable(() => import("@organisms/Modal"));
const GdprBanner = loadable(() => import("@organisms/GdprBanner"));
const Themer = loadable(() => import("../atoms/Themer"));

const createPromise = () => {
  let args;
  // eslint-disable-next-line no-return-assign, no-promise-executor-return
  const promise = new Promise((..._) => (args = _));
  return [promise, ...args];
};
const [promise, resolve] = createPromise();
const loadFeatures = () => promise;

const DefaultLayout = ({ children, path, pageContext }) => {
  // load animation features
  useEffect(() => {
    resolve(import("@utils/animationFeatures").then(r => r.domMax));
  }, []);

  const [{ layout }] = useAppState();
  const { theme: _theme } = layout;
  const theme = pageContext?.theme || _theme;

  return (
    <LazyMotion features={loadFeatures}>
      <div
        id="app_layout"
        className={classNames("lakota transition duration-500", {
          "bg-gray-light": theme === "light",
          "bg-brown": theme !== "light",
        })}
      >
        <LakotaHeader />
        {children}

        <GdprBanner />
        <Modal />
        <Themer />
        <div id="fb-root" />
      </div>
    </LazyMotion>
  );
};

export default DefaultLayout;
